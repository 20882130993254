import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import deped from "../../../shared/assets/images/DepEd-MATATAG_BagongPilipinas.png";

// Miguel: !revisit - update <a> tags to <Link> once the SliderFlick.js issue is resolved

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const backOfficeUrl = process.env.REACT_APP_BACK_OFFICE_BASE_URL;

  return (
    <div>
      <nav className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0 header-brand-container">
              <img src={deped} />
              {/* <img src={dpds} /> */}
            </div>
            <div className="flex items-center menu">
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <a
                    href="https://www.gov.ph"
                    target="_blank"
                    className=" hover:bg-gray-700 text-white px-3 py-2  text-sm font-medium"
                  >
                    GOV.PH
                  </a>

                  <a
                    href="/"
                    className=" hover:bg-gray-700 text-white px-3 py-2  text-sm font-medium"
                  >
                    Home
                  </a>

                  <a
                    href="#"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2  text-sm font-medium menu-item"
                  >
                    <span className="flex">
                      About Us <img src="/icons/angle-down-solid.svg" />
                    </span>

                    <ul className="drop-menu">
                      <li className="drop-menu-item">
                        <a href="/about-overview">Overview of Partnership</a>
                      </li>
                      <li className="drop-menu-item">
                        <a href="/about-orgchart">
                          External Partnerships Service
                        </a>
                      </li>
                      {/* <li className="drop-menu-item">
                        <a href="/about-citizen">Citizen Charter</a>
                      </li> */}
                    </ul>
                  </a>

                  <a
                    href="#"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2  text-sm font-medium menu-item"
                  >
                    <span className="flex">
                      DepEd Partners <img src="/icons/angle-down-solid.svg" />
                    </span>

                    <ul className="drop-menu">
                      {/* <li className="drop-menu-item">
                        <a href="/public-dashboard">Public Dashboard</a>
                      </li> */}
                      <li className="drop-menu-item">
                        <a href="/deped-partners">List of DepEd Partners</a>
                      </li>
                    </ul>
                  </a>

                  <a
                    href="/faqs"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2  text-sm font-medium"
                  >
                    FAQs
                  </a>

                  <a
                    href="/contact-us"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2  text-sm font-medium"
                  >
                    Contact Us
                  </a>

                  {/* <a
                    href="#"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2  text-sm font-medium"
                  >
                    Reports
                  </a> */}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
            <a
              href={backOfficeUrl}
              target="_blank"
              style={{ color: "white !important", backgroundColor: "#0038A8" }}
              className="font-bold py-2 px-4 rounded text-white login-btn"
              rel="noreferrer"
            >
              Log In
            </a>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <a
                  href="/"
                  className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Home
                </a>

                <a
                  href="#"
                  className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium menu-item"
                >
                  <span className="flex">
                    About Us <img src="/icons/angle-down-solid.svg" />
                  </span>
                  <ul className="drop-menu">
                      <li className="drop-menu-item">
                        <a href="/about-overview">Overview of Partnership</a>
                      </li>
                      <li className="drop-menu-item">
                        <a href="/about-orgchart">
                          External Partnerships Service
                        </a>
                      </li>
                      {/* <li className="drop-menu-item">
                        <a href="/about-citizen">Citizen Charter</a>
                      </li> */}
                    </ul>
                </a>

                <a
                  href="#"
                  className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium menu-item"
                >
                  <span className="flex">
                    DepEd Partners <img src="/icons/angle-down-solid.svg" />
                  </span>
                  <ul className="drop-menu">
                      {/* <li className="drop-menu-item">
                        <a href="/public-dashboard">Public Dashboard</a>
                      </li> */}
                      <li className="drop-menu-item">
                        <a href="/deped-partners">List of DepEd Partners</a>
                      </li>
                    </ul>
                </a>
                <a
                  href="/faqs"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  FAQs
                </a>

                <a
                  href="/contact-us"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contact Us
                </a>

                <a
                  href="#"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Reports
                </a>
                <a
                  href={backOfficeUrl}
                  target="_blank"
                  style={{
                    color: "white !important",
                    background: "rgb(0, 56, 168) !important",
                  }}
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  rel="noreferrer"
                >
                  Log In
                </a>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Nav;
